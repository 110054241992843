// bootstrap css
@import "~ngx-toastr/toastr";
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/switches";

//NgSelect
@import "~@ng-select/ng-select/themes/default.theme.css";

// icon
// @import "themes/font/datta-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
// @import "themes/font/flaticon";

.toast-container {
    position: fixed !important;
}

$mynewcolor: #4a2a82;

.modal-header .bg-primary {
    background-color: #4a2a82 !important;
    color: #fff;
    h5 {
        color: #fff;
    }
}

//Control Validations
ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #dc3545 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

input.ng-invalid.ng-touched {
    border-color: #dc3545 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

textarea.ng-invalid.ng-touched {
    border-color: #dc3545 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

.bg-blue {
    background-color: #4099ff;
}

@import "bootstrap";

:root {
    $primary: #4a2a82;
}

.text-primary,
.border-primary {
    color: $mynewcolor !important;
}

/* ------- customize primary button color -------- */

.btn-primary {
    @include button-variant(
        $mynewcolor,
        darken($mynewcolor, 7.5%),
        darken(#fff, 10%),
        // lighten($mynewcolor, 5%),
        // lighten($mynewcolor, 10%),
        // darken($mynewcolor, 30%)
    );
}

.btn-outline-primary {
    @include button-outline-variant(
        $mynewcolor,
        darken(#fff, 10%),
        lighten($mynewcolor, 5%),
        $mynewcolor
    );
}

.wizard {
    .completed {
        .bs-stepper-label {
            color: $mynewcolor;
        }
        .bs-stepper-circle {
            background-color: $mynewcolor;
        }
    }
    .completed.line {
        background-color: $mynewcolor;
    }
}

.hidden {
    display: none !important;
}
