/* You can add global styles to this file, and also import other style files */
@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import "scss/style";
/*================================================
Default CSS
=================================================*/
$main-font-family: "Open Sans", sans-serif;
$optional-font-family: "Catamaran", sans-serif;
$main-color: #4a2a82;
$optional-color: #10dce8;
$white-color: #ffffff;
$black-color: #212121;
$paragraph-color: #666666;
$font-size: 15px;
$transition: 0.5s;
$primary: #4a2a82;

.close,
.close:not(:disabled):not(.disabled):hover {
    text-shadow: none;
    color: $paragraph-color;
    opacity: 1;
}

.modal-open {
    overflow: hidden;
}

.budget.dropdown-menu.show {
    display: flex !important;
    flex-direction: column;
}

.datatable-body-row.active .datatable-row-group {
    background-color: #56a5ff !important;
}

.expandable .datatable-scroll {
    width: 100% !important;
    .datatable-group-header {
        width: 100% !important;
    }
    .datatable-body-cell {
        padding-top: 0 !important;
    }
}

.material .datatable-group-header {
    .datatable-body-cell {
        font-size: 12px !important;
        font-weight: 500 !important;
    }
}

.form-group {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}
body {
    padding: 0;
    margin: 0;
    position: relative;
    font: {
        family: $main-font-family;
        size: $font-size;
    }
}
a {
    text-decoration: none;
    transition: $transition;
    color: $black-color;

    &:hover,
    &:focus {
        color: $main-color;
        text-decoration: none;
    }
}
button {
    outline: 0 !important;
}
p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: $paragraph-color;
    font: {
        family: $main-font-family;
        size: $font-size;
        weight: 400;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
i {
    line-height: normal;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-50 {
    padding: {
        top: 50px;
        bottom: 50px;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black-color;
    text-transform: capitalize;
    font: {
        family: $optional-font-family;
        weight: bold;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.default-btn {
    display: inline-block;
    padding: 12px 35px 13px;
    background-color: $main-color;
    color: $white-color;
    border-radius: 2px;
    font: {
        size: $font-size;
        weight: 600;
    }
    &:hover {
        background-color: $optional-color;
        color: $white-color;
        span {
            color: $main-color;
        }
    }
    span {
        color: $white-color;
    }
}
.optional-btn {
    display: inline-block;
    padding: 13px 35px 14px;
    background-color: $optional-color;
    color: $white-color;
    border-radius: 2px;
    margin-left: 16px;
    font: {
        size: $font-size;
        weight: 500;
    }
    &:hover {
        background-color: $main-color;
        color: $white-color;
    }
}
.section-title {
    text-align: center;
    margin: {
        top: -5px;
        bottom: 60px;
    }
    h2 {
        font-size: 40px;
        margin-bottom: 12px;
        position: relative;
        line-height: 1;
    }
    span {
        color: $main-color;
    }
    p {
        max-width: 600px;
        margin: auto;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        margin: 20px auto 0;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
}
@-webkit-keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(85px);
    }
}
@keyframes MOVE-BG {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(85px);
    }
}
.default-shape {
    .shape-1 {
        position: absolute;
        right: 10%;
        top: 16%;
        z-index: -1;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-2 {
        position: absolute;
        left: 10%;
        top: 16%;
        z-index: -1;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .shape-3 {
        position: absolute;
        left: 5%;
        bottom: 25%;
        z-index: -1;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-4 {
        position: absolute;
        right: 10%;
        bottom: 25%;
        z-index: -1;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-5 {
        position: absolute;
        left: 50%;
        top: 10%;
        z-index: -1;
        animation: animationFramesOne 20s infinite linear;
    }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $main-color;
    z-index: 999999;

    .preloader {
        width: 80px;
        height: 80px;
        display: inline-block;
        padding: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        span {
            position: absolute;
            display: inline-block;
            width: 80px;
            height: 80px;
            border-radius: 100%;
            background-color: $white-color;
            -webkit-animation: preloader 1.3s linear infinite;
            animation: preloader 1.3s linear infinite;

            &:last-child {
                animation-delay: -0.8s;
                -webkit-animation-delay: -0.8s;
            }
        }
    }
}
@keyframes preloader {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }
    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}
@-webkit-keyframes preloader {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area {
    .navbar {
        transition: $transition;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
            }
            .nav-item {
                position: relative;
                padding: 0;

                a {
                    color: $black-color;
                    text-transform: capitalize;
                    font: {
                        size: 15.5px;
                        weight: 600;
                    }
                    padding: {
                        left: 0;
                        right: 0;
                        top: 25px;
                        bottom: 25px;
                    }
                    margin: {
                        left: 14px;
                        right: 14px;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 16px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -2px;
                            right: -4px;
                        }
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover,
                &.active {
                    a {
                        color: $main-color;
                        transition: $transition;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    margin-top: 20px;
                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }
                    li {
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            position: relative;
                            color: $black-color;
                            font: {
                                size: 14.5px;
                                weight: 600;
                            }
                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                                letter-spacing: 1px;
                                &::before {
                                    display: none;
                                }
                            }
                        }
                        .dropdown-menu {
                            left: -255px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 220px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: $black-color;

                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: $black-color;

                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: $black-color;

                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: $black-color;

                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: $black-color;

                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-options {
            margin-left: auto;

            .option-item {
                color: $black-color;
                display: inline-block;
                position: relative;
                line-height: 1;

                .search-btn {
                    cursor: pointer;
                    transition: $transition;
                    color: $black-color;
                    font-size: 20px;
                    font-weight: 600;
                }
                .close-btn {
                    cursor: pointer;
                    display: none;
                    transition: $transition;
                    width: 20px;
                    color: $black-color;
                    font-size: 18px;

                    &.active {
                        display: block;
                    }
                }
            }
            .burger-menu {
                width: 35px;
                height: auto;
                background-color: transparent;
                margin-left: 20px;
                cursor: pointer;
                display: inline-block;
                position: relative;
                top: 1px;

                span {
                    display: block;
                    width: 35px;
                    height: 3px;
                    background: $black-color;
                    transition: $transition;
                    border-radius: 30px;
                    margin: {
                        left: auto;
                        bottom: 4px;
                    }
                    &:nth-child(3) {
                        width: 28px;
                        margin: {
                            left: auto;
                            bottom: 0;
                        }
                    }
                }
                &:hover {
                    span {
                        background: $main-color;

                        &:nth-child(3) {
                            width: 35px;
                        }
                    }
                }
            }
            .default-btn {
                margin-left: 20px;
            }
        }
    }
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
    &.navbar-style-two {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 999;

        &.is-sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            background-color: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        }
        &.bg-color {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 23px;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
    }
}
.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    overflow: hidden;

    .sidebar-modal-inner {
        position: absolute;
        right: -100%;
        top: 0;
        width: 30%;
        overflow-y: scroll;
        height: 100%;
        background-color: $white-color;
        transition: 0.7s;
        z-index: 1;
        padding: {
            top: 80px;
            bottom: 40px;
            left: 40px;
            right: 40px;
        }
        .close-btn {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 20px;
            font-size: 20px;
            transition: $transition;
            color: $black-color;
            opacity: 0.66;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }
    .sidebar-about-area {
        margin: {
            bottom: 40px;
        }
        .title {
            h2 {
                margin-bottom: 0;
                font: {
                    size: 24px;
                    weight: 700;
                }
                font-family: $main-font-family;
            }
            p {
                margin-top: 15px;
                font-size: 15px;
            }
        }
    }
    .sidebar-contact-feed {
        margin-bottom: 40px;

        h2 {
            margin-bottom: 25px;
            font: {
                size: 22px;
            }
        }
        .contact-form {
            padding: 0;
            box-shadow: unset;

            form {
                .form-group {
                    margin-bottom: 15px;
                }
                .form-control {
                    height: 50px;
                    padding: 0 15px;
                    line-height: initial;
                    color: $black-color;
                    background-color: transparent;
                    border: 1px solid #f3f3f3;
                    border-radius: 5px;
                    transition: $transition;
                    box-shadow: unset !important;
                    font: {
                        size: 14px;
                        weight: 400;
                    }
                    &:focus {
                        border-color: $main-color;
                    }
                    &::placeholder {
                        color: #666666;
                    }
                }
                textarea.form-control {
                    height: auto !important;
                    padding-top: 15px;
                }
            }
            .send-btn {
                margin-top: 10px;
            }
            .send-btn {
                .send-btn-one {
                    display: inline-block;
                    padding: 14px 35px;
                    background-color: $main-color;
                    color: $white-color;
                    border-radius: 2px;
                    transition: $transition;
                    border: none;
                    font: {
                        size: $font-size;
                        weight: 600;
                    }
                    &:hover {
                        background-color: $optional-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    .sidebar-contact-area {
        .contact-info {
            text-align: center;

            .contact-info-content {
                h2 {
                    margin-bottom: 0;
                    text-transform: lowercase;
                    font: {
                        size: 20px;
                        family: $main-font-family;
                    }
                    a {
                        display: inline-block;
                        color: $main-color;

                        &:hover {
                            color: $black-color;
                        }
                        &:not(:first-child) {
                            color: $black-color;

                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    span {
                        display: block;
                        color: $paragraph-color;
                        margin: {
                            top: 15px;
                            bottom: 10px;
                        }
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 20px;
                    }
                    li {
                        display: inline-block;
                        margin: 0 4px;
                        padding-left: 0;

                        a {
                            width: 35px;
                            height: 35px;
                            line-height: 32px;
                            border: 1px solid #dadada;
                            border-radius: 50%;
                            color: #aba5a5;
                            display: block;

                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.active {
        opacity: 1;
        visibility: visible;

        .sidebar-modal-inner {
            right: 0;
        }
    }
}
.navbar-area {
    .navbar-light {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 767px) {
    .navbar-area {
        padding: {
            top: 15px;
            bottom: 15px;
        }
        .navbar-light {
            .navbar-toggler {
                border-color: $black-color;
                border-radius: 0;
            }
        }
        .collapse:not(.show).active {
            display: block;
        }
        .navbar {
            .navbar-nav {
                margin: 15px 0 0;
                background-color: $main-color;
                padding: 5px 15px;
                height: 350px;
                overflow-y: scroll;

                .nav-item {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    a {
                        font-size: 15px;
                        padding-left: 0;
                        padding-right: 0;
                        padding-top: 0;
                        color: $white-color !important;
                        padding-bottom: 0;
                        margin-left: 0;
                        margin-right: 0;
                    }
                    .dropdown-menu {
                        box-shadow: unset;
                        position: relative;
                        background-color: transparent !important;
                        border: none;
                        top: 0;
                        left: 0;
                        margin-top: 0 !important;
                        width: 100%;
                        z-index: 99;
                        display: block;
                        opacity: 1;
                        visibility: visible;
                        padding: 5px 20px 0;

                        li {
                            a {
                                font-size: 14px;
                                padding-left: 0 !important;
                            }
                            .dropdown-menu {
                                left: 0;
                                top: 0;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            top: 0;
                        }
                    }
                }
            }
            .others-options {
                display: none;
            }
        }
    }
}

/*================================================
Banner Area CSS
=================================================*/
.main-banner-area {
    position: relative;
    overflow: hidden;
}
.home-sliders {
    .home-item {
        position: relative;
        height: 850px;
        z-index: 1;
        background: {
            position: center;
            size: cover;
            repeat: no-repeat;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: $main-color;
            opacity: 0.8;
        }
        &.item-bg1 {
            background-image: url(assets/img/home-one/slider-bg.jpg);
        }
        &.item-bg2 {
            background-image: url(assets/img/home-one/slider-bg-2.jpg);
        }
        &.item-bg3 {
            background-image: url(assets/img/home-one/slider-bg-3.jpg);
        }
        .main-banner-content {
            position: relative;
            max-width: 630px;

            h1 {
                font-size: 70px;
                color: $white-color;
                margin: 5px 0 15px 0;
            }
            p {
                color: $white-color;
                margin-bottom: 0;
                max-width: 600px;
                font-size: 16px;
            }
            .banner-btn {
                margin-top: 30px;

                .default-btn {
                    background-color: $white-color;
                    color: $black-color;

                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                    }
                    &:not(:first-child) {
                        margin-left: 15px;
                        background-color: $optional-color;
                        color: $black-color;

                        &:hover {
                            color: $white-color;
                            background-color: $black-color;
                        }
                    }
                }
            }
        }
    }
    .banner-image {
        position: absolute;
        top: 10px;
        right: 0;
        text-align: right;

        .banner-img {
            position: absolute;
            top: 70px;
            right: 0;
            text-align: right;
            max-width: 550px;
        }
        img {
            &:nth-child(1) {
                animation: movescale 4s linear infinite;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            [class*="owl-"] {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: $white-color;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: $black-color;
                border-radius: 2px;
                width: 55px;
                height: 55px;
                transition: $transition;
                border: none;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 20px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        .owl-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 25px;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 5px;
                    background: $white-color;
                    transition: $transition;
                    border-radius: 50%;
                    transform: scale(0.9);
                }
                &:hover,
                &.active {
                    span {
                        background-color: $optional-color;
                    }
                }
                &.active {
                    span {
                        transform: scale(1.2);
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@keyframes movescale {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

/*================================================
Features Area CSS
=================================================*/
.features-content {
    text-align: center;
    margin-bottom: 30px;

    .icon {
        display: inline-block;
        height: 75px;
        width: 75px;
        line-height: 75px;
        background-color: #e7fdff;
        color: $optional-color;
        text-align: center;
        border-radius: 50px;
        transition: $transition;
        font-size: 35px;

        i {
            &.flaticon-security {
                position: relative;
                left: 3px;
            }
        }
    }
    h3 {
        font-size: 22px;
        margin: {
            top: 20px;
            bottom: 8px;
        }
    }
    &:hover {
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

/*================================================
Creative Area CSS
=================================================*/
.creative-content {
    h3 {
        font-size: 40px;
        margin-bottom: 16px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
        }
    }
    span {
        color: $main-color;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 16px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
}
.skill-bar {
    margin-top: 30px;
    font-family: $main-font-family;

    .progress-title-holder {
        position: relative;
        margin-bottom: 10px;
    }
    .progress-title {
        color: $black-color;
        font: {
            size: 18px;
            weight: 600;
        }
    }
    .progress-number-wrapper {
        width: 100%;
        z-index: 10;
        line-height: 24px;
        height: 24px;
        text-transform: none;
        color: $white-color;
        letter-spacing: 0px;
        font: {
            weight: 600;
            style: normal;
            size: 11px;
        }
    }
    .progress-number-mark {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        color: $black-color;
        font: {
            size: 16px;
            weight: 500;
        }
    }
    .progress-content-outter {
        height: 6px;
        background-color: #e4e4e4;
        border-radius: 4px;
    }
    .progress-content {
        height: 6px;
        background: linear-gradient(
            to right,
            #bcf2f5,
            #b0eaf7,
            #a9e0f9,
            #a7d6f9,
            #abcbf6
        );
        border-radius: 4px;
        width: 0%;
    }
}
.creative-image {
    height: 100%;
    position: relative;
    background: {
        image: url(assets/img/about/about.jpg);
        size: cover;
        position: center center;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: $white-color;
        border-radius: 50%;
        color: $black-color;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        font-size: 35px;
        text-align: center;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear 1s infinite;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear infinite;
        }
        &:hover,
        &:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-section {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-color {
        background-color: #f0f4f8;
        border-bottom: 1px solid #f3f3f3;
    }
    &.bg-background {
        background: {
            image: url(assets/img/services-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.services-shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.single-services-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    transition: $transition;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

    .icon {
        transition: $transition;
        display: inline-block;
        height: 70px;
        width: 90px;
        line-height: 70px;
        background-color: $main-color;
        text-align: center;
        font-size: 30px;
        color: $white-color;
        border-radius: 30px 5px 30px 5px;
        transition: $transition;

        &.bg-deb0fe {
            background-color: #deb0fe;
            color: $white-color;
        }
        &.bg-79e8e2 {
            background-color: #79e8e2;
            color: $white-color;
        }
        &.bg-fcc774 {
            background-color: #fcc774;
            color: $white-color;
        }
        &.bg-84b7fd {
            background-color: #84b7fd;
            color: $white-color;
        }
        &.bg-fe929f {
            background-color: #fe929f;
            color: $white-color;
        }
        &.bg-2e1342 {
            background-color: #2e1342;
            color: $white-color;
        }
    }
    h3 {
        font-size: 22px;
        transition: $transition;
        margin: {
            top: 25px;
            bottom: 8px;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
    }
    .read-btn {
        display: inline-block;
        margin-top: 15px;
        font: {
            weight: 600;
            size: $font-size;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 0%;
        background: $main-color;
        transition: $transition;
        border-radius: 5px;
    }
    &:hover {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .read-btn {
            color: $white-color;
        }
        .icon {
            border-radius: 5px 30px 5px 30px;
            background-color: $white-color !important;
            color: $main-color !important;
        }
    }
}
.single-services-two {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: $transition;
    border: 1px dashed $main-color;

    .icon {
        color: $main-color;
        font-size: 50px;
        transition: $transition;
    }
    h3 {
        font-size: 25px;
        transition: $transition;
        margin: {
            top: 15px;
            bottom: 10px;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
    }
    .read-btn {
        display: inline-block;
        margin-top: 15px;
        font: {
            weight: 600;
            size: $font-size;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 0%;
        background: $main-color;
        border-radius: 0;
        transition: $transition;
        border-radius: 10px;
    }
    &:hover {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .read-btn {
            color: $white-color;
        }
        .icon {
            color: $white-color;
        }
    }
    &.bg-fc9ba7 {
        border: 1px dashed #fc9ba7;

        .icon {
            color: #fc9ba7;
        }
        &::before {
            background: #fc9ba7;
        }
        &:hover {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

            &::before {
                height: 100%;
            }
            .icon {
                color: $white-color;
            }
        }
    }
    &.bg-2cdcf7 {
        border: 1px dashed #2cdcf7;

        .icon {
            color: #2cdcf7;
        }
        &::before {
            background: #2cdcf7;
        }
        &:hover {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

            &::before {
                height: 100%;
            }
            .icon {
                color: $white-color;
            }
        }
    }
    &.bg-009af0 {
        border: 1px dashed #009af0;

        .icon {
            color: #009af0;
        }
        &::before {
            background: #009af0;
        }
        &:hover {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

            &::before {
                height: 100%;
            }
            .icon {
                color: $white-color;
            }
        }
    }
    &.bg-f4d62c {
        border: 1px dashed #f4d62c;

        .icon {
            color: #f4d62c;
        }
        &::before {
            background: #f4d62c;
        }
        &:hover {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

            &::before {
                height: 100%;
            }
            .icon {
                color: $white-color;
            }
        }
    }
    &.bg-1e2d75 {
        border: 1px dashed #1e2d75;

        .icon {
            color: #1e2d75;
        }
        &::before {
            background: #1e2d75;
        }
        &:hover {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

            &::before {
                height: 100%;
            }
            .icon {
                color: $white-color;
            }
        }
    }
}
@-webkit-keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@-webkit-keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@-webkit-keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@-webkit-keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@-webkit-keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@-webkit-keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Development Area CSS
=================================================*/
.development-text {
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
        }
    }
    span {
        color: $main-color;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 16px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
}
.development-content {
    position: relative;
    padding-left: 90px;
    margin-top: 30px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;

        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
        }
        &.bg-05dbcf {
            i {
                background-color: #05dbcf;
            }
        }
        &.bg-fec66f {
            i {
                background-color: #fec66f;
            }
        }
        &.bg-66a6ff {
            i {
                background-color: #66a6ff;
            }
        }
    }
    span {
        color: $main-color;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 6px;
    }
}

/*================================================
Video Section CSS
=================================================*/
.video-section {
    background: {
        image: url(assets/img/video-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding: {
        top: 200px;
        bottom: 200px;
    }
}
.video-content {
    text-align: center;
    position: relative;

    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: $white-color;
        border-radius: 50%;
        color: $black-color;
        position: relative;
        z-index: 1;
        font-size: 30px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear 1s infinite;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
            animation: ripple 2s linear infinite;
        }
        &:hover,
        &:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    75% {
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
Choose Area CSS
=================================================*/
.choose-content {
    position: relative;
    margin-top: 40px;
    padding: 20px 40px 20px 120px;
    background-color: #f5f9fd;
    border-radius: 50px;
    transition: $transition;
    z-index: 1;

    .icon {
        position: absolute;
        left: 30px;
        top: 26px;
        transition: $transition;

        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
            transition: $transition;
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 8px;
        transition: $transition;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 0%;
        height: 100%;
        background: $main-color;
        border-radius: 0;
        transition: $transition;
        border-radius: 50px;
    }
    p {
        transition: $transition;
    }
    &:hover {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

        &::before {
            width: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .icon {
            i {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
    &:first-child {
        margin-top: 0;
    }
}
.choose-text {
    position: relative;
    padding-left: 88px;
    margin-bottom: 30px;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        width: 70px;
        background: linear-gradient(
            to right,
            #85edfe,
            #6ee0ff,
            #60d2ff,
            #5fc2ff,
            #6bb1ff
        );
        line-height: 70px;
        border-radius: 50px;
        color: $white-color;
        font-size: 30px;
        text-align: center;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 8px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.image {
    text-align: center;
}

/*================================================
Projects Area CSS
=================================================*/
.single-projects-box {
    position: relative;
    margin-bottom: 30px;
    text-align: center;

    &.two {
        margin-bottom: 0;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    .projects-content {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        right: 0;
        color: $white-color;
        margin-top: 15px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        h3 {
            color: $white-color;
            font-size: 22px;
            transition: $transition;
            margin-bottom: 6px;
        }
        span {
            color: $white-color;
            font-weight: 600;
            transition: $transition;
        }
    }
    .link-btn {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 2;
    }
    .projects-image {
        img {
            width: 100%;
        }
    }
    &:hover {
        &::before {
            opacity: 0.8;
            visibility: visible;
        }
        .projects-content {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}

/*================================================
Clients Area CSS
=================================================*/
.clients-section {
    &.bg-background {
        background: {
            image: url("../src/assets/img/map-image.png");
            position: center center;
            size: cover;
            repeat: no-repeat;
            color: $black-color;
        }
        .section-title {
            h2 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
        }
        .clients-slider {
            .clients-item {
                p {
                    color: $white-color;
                }
                .clients-content {
                    h3 {
                        color: $white-color;
                    }
                    span {
                        color: $white-color;
                    }
                }
            }
        }
    }
}
.clients-slider {
    &.owl-theme {
        max-width: 720px;
        margin: {
            left: auto;
            right: auto;
        }
        .owl-dots {
            line-height: 1;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 5px;
                    background: #c8c3c3;
                    transition: $transition;
                    border-radius: 50%;
                    transform: scale(0.9);
                }
                &:hover,
                &.active {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 30px;
        }
    }
}
.clients-item {
    text-align: center;

    .icon {
        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $main-color;
            color: $white-color;
            font-size: 40px;
            border-radius: 50px;
        }
    }
    p {
        margin-top: 20px;
        font: {
            style: italic;
            size: 17px;
        }
    }
    .clients-content {
        margin-top: 65px;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            height: 2px;
            width: 30px;
            background-color: $main-color;
            left: 0;
            right: 0;
            margin: auto;
            transform: rotate(90deg);
            top: -35px;
        }
        h3 {
            font-size: 22px;
            margin-bottom: 5px;
        }
        span {
            color: $main-font-family;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-item {
    margin-bottom: 30px;

    .image {
        overflow: hidden;

        a {
            display: block;

            img {
                transition: $transition;
            }
        }
    }
    .content {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
        padding: 25px;
        transition: $transition;

        span {
            font-size: 14px;
            color: $main-color;
        }
        h3 {
            font-size: 22px;
            margin: {
                top: 10px;
                bottom: 6px;
            }
            a {
                display: inline-block;
                line-height: 1.3;
            }
        }
        .read-more {
            font: {
                weight: 600;
            }
        }
    }
    &:hover {
        .image {
            a {
                transform: scale(1.09);
            }
        }
    }
}

/*================================================
Banner Area Two CSS
=================================================*/
.main-banner-area-two {
    position: relative;
    height: 100vh;
    z-index: 1;
    background-color: #d0e8fc;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;

    .main-banner-content {
        position: relative;
        max-width: 530px;
        margin: {
            left: auto;
            top: -130px;
        }
        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 5px 0 15px 0;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    .banner-image {
        position: relative;
        margin-top: -40px;

        img {
            position: absolute;

            &:nth-child(1) {
                top: 12px;
                left: 0;
                z-index: 999;
                max-width: 156px;
            }
            &:nth-child(2) {
                left: 16%;
                top: -214px;
                z-index: 999;
            }
            &:nth-child(3) {
                left: 30%;
                z-index: 999;
                top: -246px;
            }
            &:nth-child(4) {
                right: -24px;
                top: -172px;
                max-width: 820px;
            }
            &:nth-child(5) {
                top: 12px;
                right: 0;
                z-index: 999;
                max-width: 156px;
            }
            &:last-child {
                display: none;
            }
        }
    }
}

/*================================================
Banner Area Three CSS
=================================================*/
.main-banner-area-three {
    position: relative;
    height: 780px;
    z-index: 1;
    overflow: hidden;
    background: {
        size: cover;
        position: center center;
        repeat: no-repeat;
        image: url(assets/img/home-three/banner-bg1.jpg);
    }
    .main-banner-content {
        position: relative;
        max-width: 635px;
        margin-left: auto;

        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 10px 0 10px 0;
        }
        span {
            font-size: 16px;
            color: $main-color;
            font-weight: 600;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-form {
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
            0 10px 10px rgba(0, 0, 0, 0.02);
        padding: 40px;
        position: relative;
        z-index: 1;

        &::before {
            left: 0;
            right: 0;
            height: 20px;
            content: "";
            position: absolute;
            background: #f4f4f4;
            bottom: -20px;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
                0 10px 10px rgba(0, 0, 0, 0.02);
            border-radius: 0 0 5px 5px;
            margin-left: 15px;
            margin-right: 15px;
        }
        h3 {
            margin-bottom: 25px;
            text-align: center;
            font: {
                family: $optional-font-family;
                size: 26px;
            }
        }
        form {
            .form-control {
                height: 55px;
                padding: 0 0 0 15px;
                line-height: initial;
                color: #333333;
                background-color: transparent;
                border: 1px solid #e5e0e0;
                border-radius: 0;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: 15px;
                    weight: 400;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            textarea.form-control {
                height: auto;
                padding-top: 15px;
            }
            button {
                display: block;
                width: 100%;
                margin-top: 20px;
                color: $white-color;
                background-color: $main-color;
                padding: 14px 25px;
                border: 1px solid $main-color;
                transition: $transition;
                text-transform: capitalize;
                cursor: pointer;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:hover {
                    border-color: $main-color;
                    color: $main-color;
                    background-color: transparent;
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
                        0 10px 10px rgba(0, 0, 0, 0.02);
                }
            }
        }
    }
    .banner-image {
        position: relative;
        margin-top: -40px;

        img {
            position: absolute;

            &:nth-child(1) {
                top: 12px;
                right: -30px;
                z-index: 999;
            }
            &:nth-child(2) {
                left: 45%;
                top: 100px;
                z-index: 999;
            }
            &:nth-child(3) {
                left: 100px;
                z-index: 999;
                top: 109px;
            }
            &:nth-child(4) {
                left: -112px;
                top: -85px;
            }
            &:nth-child(5) {
                top: -192px;
                left: 15%;
            }
            &:last-child {
                display: none;
            }
        }
    }
}

/*================================================
Banner Area Four CSS
=================================================*/
.main-banner-area-four {
    position: relative;
    height: 900px;
    z-index: 1;
    overflow: hidden;
    background: {
        position: center;
        size: cover;
        repeat: no-repeat;
        image: url(assets/img/home-one/slider-bg.jpg);
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: $white-color;
        opacity: 0.9;
    }
    .main-banner-content {
        position: relative;
        max-width: 630px;
        margin: {
            left: auto;
            top: 60px;
        }
        h1 {
            font-size: 70px;
            color: $black-color;
            margin: {
                top: 15px;
                bottom: 10px;
            }
        }
        span {
            color: $main-color;
            font: {
                weight: 600;
                size: 16px;
            }
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-image {
        margin-top: 60px;
    }
}

/*================================================
Banner Area Five CSS
=================================================*/
.main-banner-area-five {
    position: relative;
    height: 900px;
    z-index: 1;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;

    .main-banner-content {
        position: relative;
        max-width: 630px;
        margin-left: auto;

        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 10px 0 15px 0;
        }
        span {
            font-size: 16px;
            color: $black-color;
            font-weight: 600;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-image {
        margin-top: 60px;
        text-align: right;
    }
    .banner-shape {
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: -1;
    }
}

/*================================================
Banner Area Six CSS
=================================================*/
.main-banner-area-six {
    position: relative;
    height: 900px;
    z-index: 1;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;

    .main-banner-content {
        position: relative;
        max-width: 630px;
        margin-left: auto;

        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 10px 0 15px 0;
        }
        span {
            font-size: 16px;
            color: $black-color;
            font-weight: 600;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-image {
        margin-top: 0;
        text-align: right;
    }
    .banner-shape {
        position: absolute;
        bottom: 0px;
        right: 0;
        z-index: -1;
    }
}

/*================================================
Banner Area Seven CSS
=================================================*/
.main-banner-area-seven {
    position: relative;
    height: 900px;
    z-index: 1;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;

    .main-banner-content {
        position: relative;
        max-width: 630px;
        margin-left: auto;

        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 10px 0 15px 0;
        }
        span {
            font-size: 16px;
            color: $black-color;
            font-weight: 600;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .banner-image {
        position: relative;

        img {
            position: absolute;

            &:nth-child(1) {
                top: -24px;
                left: 8%;
                z-index: 999;
            }
            &:nth-child(2) {
                left: 35%;
                top: 60px;
                z-index: 999;
            }
            &:nth-child(3) {
                left: 28%;
                z-index: 999;
                top: -50px;
            }
            &:nth-child(4) {
                left: 36%;
                top: 40px;
                z-index: 999;
            }
            &:nth-child(5) {
                right: 0;
                top: -40px;
                z-index: 999;
            }
            &:nth-child(6) {
                right: 10%;
                top: 102px;
                z-index: 999;
            }
            &:nth-child(7) {
                right: 16%;
                top: -172px;
                z-index: 999;
            }
            &:nth-child(8) {
                right: 5px;
                top: -320px;
                z-index: 999;
            }
            &:nth-child(9) {
                left: 30%;
                top: -300px;
                z-index: 999;
            }
            &:nth-child(10) {
                left: 20%;
                top: -158px;
                z-index: 999;
            }
            &:nth-child(11) {
                right: 0;
                top: -265px;
            }
            &:last-child {
                display: none;
            }
        }
    }
}

/*================================================
Banner Area Eight CSS
=================================================*/
.main-banner-area-eight {
    position: relative;
    height: 850px;
    z-index: 1;
    border-bottom: 1px solid #f3f3f3;
    overflow: hidden;

    .main-banner-content {
        position: relative;
        max-width: 630px;
        margin-left: auto;

        h1 {
            font-size: 70px;
            color: $black-color;
            margin: 10px 0 15px 0;
        }
        span {
            font-size: 16px;
            color: $black-color;
            font-weight: 600;
        }
        p {
            color: $black-color;
            margin-bottom: 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }

    .banner-form {
        border-radius: 5px;
        background-color: $white-color;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
            0 10px 10px rgba(0, 0, 0, 0.02);
        padding: 40px;
        position: relative;
        z-index: 1;

        &::before {
            left: 0;
            right: 0;
            height: 20px;
            content: "";
            position: absolute;
            background: #f4f4f4;
            bottom: -20px;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
                0 10px 10px rgba(0, 0, 0, 0.02);
            border-radius: 0 0 5px 5px;
            margin-left: 15px;
            margin-right: 15px;
        }
        h3 {
            margin-bottom: 25px;
            text-align: center;
            font: {
                family: $optional-font-family;
                size: 26px;
            }
        }
        form {
            .form-control {
                height: 55px;
                padding: 0 0 0 15px;
                line-height: initial;
                color: #333333;
                background-color: transparent;
                border: 1px solid #e5e0e0;
                border-radius: 0;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: 15px;
                    weight: 400;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            textarea.form-control {
                height: auto;
                padding-top: 15px;
            }
            button {
                display: block;
                width: 100%;
                margin-top: 20px;
                color: $white-color;
                background-color: $main-color;
                padding: 14px 25px;
                border: 1px solid $main-color;
                transition: $transition;
                text-transform: capitalize;
                cursor: pointer;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &:hover {
                    border-color: $main-color;
                    color: $main-color;
                    background-color: transparent;
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1),
                        0 10px 10px rgba(0, 0, 0, 0.02);
                }
            }
        }
    }
}

/*================================================
Work Area CSS
=================================================*/
.work-section {
    &.bg-f6f6fe {
        background-color: #f6f6fe;
    }
}
.work-content {
    max-width: 630px;
    margin-left: auto;
    padding: {
        top: 50px;
        bottom: 50px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 12px;
    }
    span {
        color: $main-color;
    }
    p {
        margin-bottom: 0;
    }
    h4 {
        font-size: 22px;
        margin: {
            top: 12px;
            bottom: 8px;
        }
    }
    .work-status {
        position: relative;
        margin-top: 20px;

        h3 {
            margin-bottom: 0;
            line-height: 1;
            color: $main-color;
            font: {
                family: $main-font-family;
                size: 50px;
            }
            .sign-icon {
                display: inline-block;
                font-size: 46px;
                position: absolute;
                top: 6px;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
}
.work-image {
    position: relative;
    height: 100%;
    background: {
        image: url(assets/img/work-bg.jpg);
        size: cover;
        position: center center;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: $main-color;
        border-radius: 50%;
        color: $white-color;
        position: absolute;
        z-index: 1;
        left: -30px;
        top: 55%;
        transform: translateY(-55%);
        font-size: 35px;
        text-align: center;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $main-color;
            animation: ripple 2s linear 1s infinite;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $main-color;
            animation: ripple 2s linear infinite;
        }
        &:hover,
        &:focus {
            background-color: $black-color;
            color: $white-color;
        }
    }
}

/*================================================
Support Area CSS
=================================================*/
.support-content {
    position: relative;
    margin-bottom: 30px;
    padding-left: 67px;

    &:last-child {
        margin-bottom: 0;
    }
    .icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 50px;
        color: $main-color;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 6px;
    }
}

/*================================================
Overview Area CSS
=================================================*/
.overview-section {
    background-color: #f0f1fe;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.overview-content {
    text-align: center;
    margin-bottom: 30px;

    .icon {
        background-color: $white-color;
        border: 1px dashed $main-color;
        height: 100px;
        width: 100px;
        line-height: 100px;
        border-radius: 50%;
        color: $white-color;
        position: relative;
        z-index: 1;
        font-size: 30px;
        margin: {
            left: auto;
            right: auto;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            background: linear-gradient(
                to right,
                #85edfe,
                #6ee0ff,
                #60d2ff,
                #5fc2ff,
                #6bb1ff
            );
            border-radius: 50%;
            margin: 10px;
        }
    }
    h3 {
        font-size: 25px;
        margin: {
            top: 25px;
            bottom: 8px;
        }
    }
    p {
        margin-bottom: 0;
    }
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/counter-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.single-fun-fact {
    text-align: center;
    transition: $transition;
    position: relative;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 35px 30px;
    border-radius: 10px;

    h3 {
        line-height: 1;
        font: {
            family: $main-font-family;
            size: 50px;
        }
        margin: {
            top: 20px;
            bottom: 0;
        }
    }
    p {
        margin-bottom: 0;
        font: {
            weight: 500;
        }
    }
    .icon {
        height: 70px;
        width: 70px;
        line-height: 70px;
        text-align: center;
        background-color: #e2edfd;
        color: $main-color;
        font-size: 30px;
        border-radius: 50px;
        transition: $transition;
        margin: {
            left: auto;
            right: auto;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-team-box {
    margin-bottom: 30px;
    transition: $transition;
    position: relative;

    .content {
        background-color: $white-color;
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
        padding: 20px;
        max-width: 300px;
        margin: -80px auto 0;
        text-align: center;
        position: relative;
        z-index: 1;
        transition: $transition;

        h3 {
            font-size: 22px;
            margin-bottom: 6px;
        }
        span {
            font-size: 15px;
            font-weight: 500;
        }
        .social {
            padding-left: 0;
            margin-bottom: 0;
            margin-top: -40px;
            opacity: 0;
            transition: $transition;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
            i {
                display: inline-block;
                height: 40px;
                width: 40px;
                line-height: 40px;
                background-color: $main-color;
                color: $white-color;
                font-size: 14px;
                border-radius: 30px;
                transition: $transition;

                &:hover {
                    background-color: $optional-color;
                }
            }
        }
    }
    &:hover {
        .content {
            .social {
                margin-top: 15px;
                opacity: 1;
            }
        }
    }
}
.team-item {
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    .image {
        position: relative;
        overflow: hidden;
        transition: $transition;

        .social {
            padding-left: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            }
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 1px;
                    right: 1px;
                }
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: $main-color;
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: $white-color;
                    border-radius: 30px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        }
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
                &:nth-child(3) {
                    transition-delay: 0.4s;
                }
                &:nth-child(4) {
                    transition-delay: 0.5s;
                }
            }
        }
    }
    .content {
        background-color: $white-color;
        box-shadow: 1px 20px 30px rgba(0, 0, 0, 0.07);
        padding: 30px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
                family: $main-font-family;
            }
        }
        span {
            display: block;
            color: $main-color;
            margin-top: 7px;
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

/*================================================
Skill Area CSS
=================================================*/
.skills-section {
    overflow: hidden;
}
.skills-image {
    width: 100%;
    height: 600px;
    background: {
        image: url(assets/img/skills.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.skills-area {
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    background-color: $white-color;
    padding: 50px;
    margin: {
        left: -200px;
        right: 200px;
    }
    .skills-content {
        h3 {
            font-size: 40px;
            margin-bottom: 16px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
            }
        }
        span {
            color: $main-color;
        }
        .bar {
            height: 4px;
            width: 85px;
            background: $main-color;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 16px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background: $white-color;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-name: MOVE-BG;
            }
        }
    }
    .skill-bar {
        margin-top: 30px;
        font-family: $main-font-family;

        .progress-title-holder {
            position: relative;
            margin-bottom: 10px;
        }
        .progress-title {
            font-size: 18px;
            font-weight: 600;
            color: $black-color;
        }
        .progress-number-wrapper {
            width: 100%;
            z-index: 10;
            line-height: 24px;
            height: 24px;
            letter-spacing: 0px;
            text-transform: none;
            color: $white-color;
            font: {
                weight: 600;
                style: normal;
                size: 11px;
            }
        }
        .progress-number-mark {
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);
            color: $black-color;
            font: {
                size: 16px;
                weight: 500;
            }
        }
        .progress-content-outter {
            height: 6px;
            background-color: #e4e4e4;
            border-radius: 4px;
        }
        .progress-content {
            height: 6px;
            background: linear-gradient(
                to right,
                #bcf2f5,
                #b0eaf7,
                #a9e0f9,
                #a7d6f9,
                #abcbf6
            );
            border-radius: 4px;
            width: 0%;
        }
    }
}

/*================================================
Audience Area CSS
=================================================*/
.audience-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.audience-content {
    h3 {
        font-size: 40px;
        margin-bottom: 14px;
        position: relative;

        span {
            color: $main-color;
        }
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .audience-btn {
        margin-top: 25px;
    }
}

/*================================================
Solutions Area CSS
=================================================*/
.solutions-content-area {
    max-width: 635px;
    margin-left: auto;
    padding: {
        top: 50px;
        bottom: 50px;
    }
    .solutions-content {
        h3 {
            font-size: 40px;
            margin-bottom: 12px;
            position: relative;

            span {
                color: $main-color;
            }
        }
        .bar {
            height: 4px;
            width: 85px;
            background: $main-color;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background: $white-color;
                animation: {
                    duration: 2s;
                    timing-function: linear;
                    iteration-count: infinite;
                    name: MOVE-BG;
                }
            }
        }
        .audience-btn {
            margin-top: 25px;
        }
    }
    .solutions-details {
        margin-top: 30px;
        position: relative;
        padding-left: 90px;

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            height: 70px;
            width: 70px;
            line-height: 70px;
            background-color: #f2e4fc;
            color: #b45df5;
            text-align: center;
            font-size: 32px;
            border-radius: 50px;

            &.bg-d3fbf9 {
                background-color: #d3fbf9;
                color: #4ee8df;
            }
            &.bg-fce8c9 {
                background-color: #fce8c9;
                color: #f7b042;
            }
            &.bg-d5e6fe {
                background-color: #d5e6fe;
                color: #7fb4ff;
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}
.solutions-image {
    height: 100%;
    background: {
        image: url(assets/img/solutions.png);
        size: cover;
        position: center center;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}

/*================================================
Design Area CSS
=================================================*/
.design-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.design-content {
    h3 {
        font-size: 40px;
        margin-bottom: 16px;
    }
    span {
        color: $main-color;
    }
    p {
        margin-bottom: 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 16px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .design-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 0;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 30px;
            color: $black-color;
            padding: {
                left: 10px;
                right: 10px;
            }
            font: {
                weight: 600;
                size: 16px;
            }
        }
        i {
            color: $main-color;
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background-color: #d7f2fc;
            text-align: center;
            margin-right: 5px;
        }
    }
}

/*================================================
Counter Area CSS
=================================================*/
.counter-section {
    background-color: #eceefe;
}
.single-counter {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 35px 30px;
    border-radius: 10px;
    transition: $transition;
    z-index: 1;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

    h3 {
        line-height: 1;
        transition: $transition;
        font: {
            family: $main-font-family;
            size: 50px;
        }
        margin: {
            top: 18px;
            bottom: 3px;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
        font: {
            weight: 500;
        }
    }
    .icon {
        height: 70px;
        width: 70px;
        line-height: 70px;
        text-align: center;
        background-color: #e2edfd;
        color: $main-color;
        font-size: 30px;
        border-radius: 50px;
        margin: {
            left: auto;
            right: auto;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 0%;
        background: $main-color;
        transition: $transition;
        border-radius: 10px;
    }
    &:hover {
        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-section {
    background-color: #faf9ff;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-pricing {
    background-color: #c2f2fd;
    padding: 50px 90px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 50px 4px 50px 4px;

    .pricing-header {
        h3 {
            font-size: 25px;
            color: $black-color;
            margin-bottom: 0;
        }
        p {
            color: $black-color;
            font-weight: 500;
        }
    }
    .price {
        display: inline-block;
        height: 100px;
        width: 100px;
        line-height: 100px;
        background-color: $white-color;
        border-radius: 50px;
        font: {
            size: 25px;
            weight: bold;
        }
        margin: {
            top: 16px;
            bottom: 16px;
        }
        sup {
            font-weight: 400;
        }
        sub {
            font-weight: 400;
        }
    }
    .pricing-list {
        padding-left: 0;
        margin-bottom: 0;
        text-align: left;

        li {
            list-style-type: none;
            padding-bottom: 12px;
            font-size: 16px;
            color: $black-color;
            font-weight: 500;

            &:last-child {
                padding-bottom: 0;
            }
        }
        i {
            color: $main-color;
            margin-right: 5px;
        }
    }
    .price-btn {
        margin-top: 25px;

        .default-btn {
            box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
            padding: 12px 30px;
            display: inline-block;
            background-color: $white-color;
            color: $black-color;
            font-weight: 600;
            border-radius: 30px;
        }
    }
    &:hover {
        .price-btn {
            .default-btn {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Protfolio Area CSS
=================================================*/
.protfolio-section {
    &.bg-color {
        background-color: #f2f0fe;
    }
}
.single-protfolio-box {
    margin-bottom: 30px;
    transition: $transition;
    background-color: $white-color;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

    .image {
        a {
            display: block;
        }
    }
    .content {
        padding: 30px;
        position: relative;

        h3 {
            font-size: 22px;
            margin-bottom: 6px;
            transition: $transition;
        }
        span {
            transition: $transition;
        }
        .link-btn {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }
    &:hover {
        background-color: $main-color;

        h3 {
            color: $white-color;
        }
        span {
            color: $white-color;
        }
    }
}

/*================================================
Process Area CSS
=================================================*/
.process-content {
    background-color: #f5f6fe;
    padding: 30px;
    margin-bottom: 30px;

    .number {
        display: inline-block;
        height: 30px;
        width: 30px;
        line-height: 30px;
        text-align: center;
        background-color: $main-color;
        color: $white-color;
        border-radius: 30px;
        font-weight: 600;
    }
    h3 {
        font-size: 25px;
        margin: {
            bottom: 0;
            top: 20px;
        }
    }
    .content {
        margin-top: 35px;

        h4 {
            font-size: 22px;
            margin: {
                bottom: 5px;
                top: 0;
            }
        }
    }
    .image {
        margin-top: 20px;
    }
}

/*================================================
About Area CSS
=================================================*/
.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.about-tab {
    max-width: 620px;
    padding: 100px 0;
    margin-left: 50px;

    h2 {
        font-size: 40px;
        margin-bottom: 14px;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .about-list-tab {
        .tabs {
            border-bottom: 1px solid #eeeeee;
            padding-left: 0;
            margin: {
                bottom: 30px;
                top: 20px;
            }
            li {
                padding: 0;
                margin-right: 30px;
                padding-bottom: 10px;
                display: inline-block;

                &.current {
                    border-bottom: 2px solid $main-color;

                    a {
                        color: $main-color;
                    }
                }
                a {
                    position: relative;
                    display: block;
                    font-weight: 600;
                }
            }
        }
        .tab_content {
            .tabs_item {
                .text {
                    h3 {
                        font-size: 22px;
                        margin-bottom: 0;
                    }
                }
                .list {
                    padding-left: 0;
                    margin: {
                        top: 20px;
                        bottom: 20px;
                    }
                    li {
                        list-style-type: none;
                        margin-bottom: 12px;
                        font: {
                            weight: 600;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 5px;
                    }
                }
                p {
                    margin-bottom: 0;
                }
                .default-btn {
                    margin-top: 30px;
                }
            }
        }
    }
}
.about-image {
    height: 100%;
    background: {
        image: url(assets/img/about/about-2.jpg);
        size: cover;
        position: center center;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}

/*================================================
Review Area CSS
=================================================*/
.review-content {
    h3 {
        font-size: 40px;
        margin-bottom: 14px;
        position: relative;

        span {
            color: $main-color;
        }
    }
    p {
        margin-bottom: 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
}
.review-text {
    position: relative;
    margin-top: 30px;
    padding-left: 35px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
        color: $main-color;
    }
    h3 {
        font-size: 22px;
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-inner-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/subscribe-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background-color: $main-color;
        opacity: 0.8;
    }
}
.subscribe-inner-text {
    text-align: center;

    h2 {
        color: $white-color;
        font-size: 40px;
        max-width: 700px;
        margin: {
            bottom: 10px;
            left: auto;
            right: auto;
        }
    }
    p {
        color: $white-color;
    }
    .newsletter-form {
        max-width: 550px;
        position: relative;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            border-radius: 70px;
            outline: 0;
            color: $black-color;
            padding: {
                left: 25px;
                top: 5px;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $black-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            line-height: 55px;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
        #validator-newsletter {
            color: red;
            margin-top: 15px;
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
    .card {
        display: block;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        background: $white-color;
        margin-bottom: 15px;
        border-radius: 0;
        border: none;

        &:last-child {
            margin-bottom: 0;
        }
        .card-header {
            border-radius: 0 !important;
            padding: 0;
            border-bottom: none;
            background-color: $white-color;

            a {
                padding: 12px 35px 10px 15px;
                color: $black-color;
                text-decoration: none;
                position: relative;
                display: block;
                border: {
                    bottom: 1px solid #eeeeee;
                }
                font: {
                    size: 16px;
                    weight: 600;
                }
                i {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    font-size: 20px;
                    transform: rotate(180deg) translateY(50%);
                }
                &.collapsed {
                    border: {
                        bottom: {
                            color: transparent;
                        }
                    }
                    i {
                        transform: rotate(0) translateY(-50%);
                    }
                }
            }
        }
        .collapse {
            .card-body {
                position: relative;
                padding: 20px;
            }
        }
    }
}

/*================================================
Learn Area CSS
=================================================*/
.learn-content {
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .learn-list {
        padding-left: 0;
        position: relative;
        margin: {
            top: 20px;
            bottom: 30px;
        }
        li {
            list-style-type: none;
            margin-bottom: 12px;
            font: {
                size: 16px;
                weight: 600;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        i {
            color: $main-color;
            margin-right: 3px;
            font-size: 20px;
            position: relative;
            top: 3px;
        }
    }
}
.learn-inner-content {
    background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    padding: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: $transition;
    border-radius: 10px;

    &.mb-30 {
        margin-bottom: 30px;
    }
    &.pb-30 {
        padding-bottom: 30px;
    }
    .icon {
        height: 70px;
        width: 70px;
        line-height: 70px;
        background-color: $main-color;
        color: $white-color;
        font-size: 35px;
        border-radius: 50px;
        transition: $transition;
        margin: {
            left: auto;
            right: auto;
        }
        &.bg-ba60fc {
            background-color: #ba60fc;
        }
        &.bg-04cfc4 {
            background-color: #04cfc4;
        }
        &.bg-f9b854 {
            background-color: #f9b854;
        }
    }
    h3 {
        font-size: 22px;
        margin: 25px 0 8px 0;
        transition: $transition;
    }
    p {
        margin-bottom: 0;
        transition: $transition;
    }
    .read-btn {
        display: inline-block;
        margin-top: 10px;
        font-weight: 600;
        transition: $transition;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 0%;
        background: $main-color;
        transition: $transition;
        border-radius: 12px;
    }
    &:hover {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);

        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .read-btn {
            color: $white-color;
        }
        .icon {
            background-color: $white-color;
            color: $main-color;
        }
    }
}

/*================================================
App Area CSS
=================================================*/
.app-content {
    padding-left: 15px;

    h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
}
.app-inner-text {
    position: relative;
    margin-top: 30px;
    padding-left: 85px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        height: 70px;
        width: 70px;
        line-height: 70px;
        background-color: $main-color;
        color: $white-color;
        text-align: center;
        font-size: 35px;
        border-radius: 50px;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 0;
    }
}

/*================================================
Data Area CSS
=================================================*/
.data-section {
    background-color: #f8fcfe;
}
.data-content {
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
}
.data-inner-content {
    background-color: $white-color;
    padding: 30px;

    i {
        display: inline-block;
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 20px;
        background-color: $main-color;
        color: $white-color;
        text-align: center;
        border-radius: 5px;
    }
    h3 {
        font-size: 22px;
        margin: 15px 0 2px 0;
    }
    &.bg-facf34 {
        i {
            background-color: #facf34;
        }
    }
}

/*================================================
Customer Area CSS
=================================================*/
.customer-section {
    background: linear-gradient(
        to right,
        #d5f8fe,
        #9be3ff,
        #71c8ff,
        #74a8ff,
        #9d7df8
    );
}
.customer-content {
    h3 {
        font-size: 40px;
        margin-bottom: 14px;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    p {
        color: $black-color;
    }
    .customer-btn {
        margin-top: 30px;
    }
}
.customer-image {
    text-align: center;
}

/*================================================
More Features Area CSS
=================================================*/
.more-features-content {
    text-align: center;
    margin-bottom: 30px;
    background-color: #f1eff8;
    border-radius: 5px;
    padding: 40px 20px;
    transition: $transition;

    .icon {
        height: 60px;
        width: 60px;
        line-height: 60px;
        background-color: $white-color;
        color: $main-color;
        font-size: 30px;
        border-radius: 50px;
        border: 1px dashed $main-color;
        margin: {
            left: auto;
            right: auto;
        }
    }
    h3 {
        font-size: 22px;
        transition: $transition;
        margin: {
            bottom: 6px;
            top: 25px;
        }
    }
    p {
        transition: $transition;
    }
    &.bg-f0fffc {
        background-color: #f0fffc;

        .icon {
            color: #22b396;
            border: 1px dashed #22b396;
        }
    }
    &.bg-fceee2 {
        background-color: #fceee2;

        .icon {
            background-color: $white-color;
            color: #f1760c;
            border: 1px dashed #f1760c;
        }
    }
    &.bg-fde2ee {
        background-color: #fde2ee;

        .icon {
            background-color: $white-color;
            color: #f23e8a;
            border: 1px dashed #f23e8a;
        }
    }
    &:hover {
        background-color: $main-color;

        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
}

/*================================================
Digital Area CSS
=================================================*/
.digital-content {
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
    }
    span {
        color: $main-color;
    }
    p {
        margin-bottom: 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .digital-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: -10px;
        margin-right: -10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 30px;
            color: $black-color;
            padding: {
                left: 10px;
                right: 10px;
            }
            font: {
                size: 16px;
                weight: 600;
            }
        }
        i {
            color: $main-color;
            display: inline-block;
            height: 25px;
            width: 25px;
            line-height: 25px;
            background-color: #d7f2fc;
            text-align: center;
            margin-right: 5px;
        }
    }
    .digital-btn {
        margin-top: 30px;
    }
}

/*================================================
Experience Area CSS
=================================================*/
.experience-inner-area {
    max-width: 630px;
    margin-left: auto;

    .experience-content {
        h2 {
            font-size: 40px;
            margin-bottom: 15px;

            span {
                color: $main-color;
            }
        }
        p {
            margin-bottom: 0;
        }
        .bar {
            height: 4px;
            width: 85px;
            background: $main-color;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background: $white-color;
                animation: {
                    duration: 2s;
                    timing-function: linear;
                    iteration-count: infinite;
                    name: MOVE-BG;
                }
            }
        }
    }
    .experience-inner-content {
        position: relative;
        margin-top: 30px;
        padding-left: 60px;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            height: 40px;
            width: 40px;
            line-height: 40px;
            background-color: $main-color;
            text-align: center;
            font-size: 20px;
            color: $white-color;
            border-radius: 2px;
        }
        h3 {
            font-size: 22px;
            margin-bottom: 8px;
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-text {
    margin-bottom: 30px;

    h2 {
        margin-bottom: 10px;
        font: {
            size: 40px;
        }
        span {
            color: $main-color;
        }
    }
}
.contact-form {
    background-color: $white-color;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
    padding: 35px;

    form {
        .form-group {
            margin-bottom: 15px;
        }
        .form-control {
            height: 48px;
            padding: 0 15px;
            line-height: initial;
            color: $black-color;
            background-color: #f8f6f6;
            border: 1px solid #f8f6f6;
            border-radius: 5px;
            transition: $transition;
            box-shadow: unset !important;
            font: {
                size: 14px;
                weight: 400;
            }
            &:focus {
                border-color: $main-color;
            }
            &::placeholder {
                color: #999999;
            }
        }
        textarea.form-control {
            height: auto !important;
            padding-top: 15px;
        }
    }
    .send-btn {
        margin-top: 10px;
        transition: $transition;

        .default-btn {
            border: none;
            display: inline-block;
            width: 100%;
        }
    }
    .with-errors {
        ul {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 5px;
                bottom: 0;
            }
            li {
                color: red;
            }
        }
    }
    #msgSubmit {
        margin-bottom: 0;
        font: {
            size: 22px;
            weight: 500;
        }
        &.text-danger {
            margin-top: 20px !important;
            font-family: $main-font-family;
        }
    }
}

/*================================================
Tech Area CSS
=================================================*/
.tech-section {
    background-color: #f6f6fe;
}
.tech-content {
    h2 {
        font-size: 40px;
        margin-bottom: 15px;

        span {
            color: $main-color;
        }
    }
    p {
        margin-bottom: 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation: {
                duration: 2s;
                timing-function: linear;
                iteration-count: infinite;
                name: MOVE-BG;
            }
        }
    }
    .tech-btn {
        margin-top: 30px;
    }
}

/*================================================
Agency Services Area CSS
=================================================*/
.agency-services-section {
    background-color: #f6f6fe;
    position: relative;
}
.single-agency-services {
    margin-bottom: 30px;
    position: relative;

    .image {
        a {
            display: block;
        }
    }
    .content {
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
        background-color: $white-color;
        padding: 30px;
        text-align: center;
        position: relative;
        z-index: 1;
        max-width: 320px;
        margin: -50px auto 0px;

        h3 {
            font-size: 22px;
            margin-bottom: 8px;
            transition: $transition;
        }
        span {
            transition: $transition;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 0%;
            background: $main-color;
            transition: $transition;
        }
        .link-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            z-index: 2;
        }
    }
    &:hover {
        .content {
            &::before {
                height: 100%;
            }
            h3 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
    }
}

/*================================================
Tab Area CSS
=================================================*/
.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.schedule-list-tab {
    .tabs {
        padding-left: 0;
        margin-bottom: 65px;
        text-align: center;

        li {
            text-align: center;
            display: inline-block;
            list-style: none;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            margin-right: 15px;
            border-radius: 10px;
            transition: $transition;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                color: $black-color;
                padding: 20px 30px;
                font: {
                    size: 16px;
                    weight: 600;
                }
                span {
                    display: block;
                    margin-top: 0;

                    i {
                        display: block;
                        font-size: 35px;
                        margin-bottom: 8px;
                        color: $main-color;
                        transition: $transition;
                    }
                }
            }
            &.current,
            &:hover {
                background-color: $main-color;

                a {
                    color: $white-color;

                    span {
                        i {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            .tab-content {
                h4 {
                    font-size: 40px;
                    margin-bottom: 8px;
                }
                p {
                    margin-bottom: 0;
                }
                .tab-inner-content {
                    margin-top: 25px;
                    position: relative;
                    padding-left: 55px;
                    transition: $transition;

                    .icon {
                        height: 40px;
                        width: 40px;
                        line-height: 40px;
                        background-color: $main-color;
                        color: $white-color;
                        text-align: center;
                        font-size: 20px;
                        border-radius: 30px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        transition: $transition;
                    }
                    h3 {
                        font-size: 22px;
                        margin-bottom: 6px;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    &:hover {
                        .icon {
                            background-color: $optional-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    height: 90px;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    // &.item-bg-1 {
    //     background-image: url(assets/img/page-title/bg.jpg);
    // }
    &.item-bg-2 {
        background-image: url(assets/img/page-title/bg-2.jpg);
    }
    &.item-bg-3 {
        background-image: url(assets/img/page-title/bg-4.jpg);
    }
    &.item-bg-4 {
        background-image: url(assets/img/page-title/bg-5.jpg);
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.77;
    }
}
.page-title-content {
    text-align: center;
    margin-top: 0;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            family: $main-font-family;
            size: 45px;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 15px;
            bottom: 0;
        }
        li {
            color: $white-color;
            display: inline-block;
            position: relative;
            font: {
                weight: 400;
                size: 17px;
            }
            margin: {
                left: 13px;
                right: 13px;
            }
            a {
                display: inline-block;
                color: $white-color;
                transition: $transition;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: "";
                position: absolute;
                right: -15.5px;
                top: 11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-color;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 20px;
    text-align: center;

    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 5px;
        display: inline-block;
        background-color: $white-color;
        line-height: 45px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            weight: 700;
        }
        &.current,
        &:hover,
        &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Login In CSS
=================================================*/
.login-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.login-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;

    .login-title {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 30px;
            font-family: $main-font-family;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 30px;
        }
    }
    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $black-color;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: #666666;
                position: relative;
                top: -2px;
                z-index: 1;
            }
        }
        .form-check-input {
            margin-top: 3px;
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn-one {
                display: inline-block;
                padding: 12px 30px;
                background: $main-color;
                border: 1px solid $main-color;
                color: $white-color;
                font-size: 16px;
                font-weight: 400;
                transition: 0.6s;

                &:hover {
                    text-decoration: none;
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        span {
            display: block;
            color: #777777;

            a {
                color: $main-color;
            }
        }
    }
    .forgot-password {
        margin-bottom: 0;
        margin-top: -25px;

        a {
            color: #777777;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Sign Up CSS
=================================================*/
.signup-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.signup-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;

    h3 {
        font-size: 30px;
        font-family: $main-font-family;
        margin-bottom: 30px;
        text-align: center;
    }
    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $black-color;
            padding-left: 20px;
            background: transparent;

            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: #666666;
                position: relative;
                top: -2px;
            }
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn-one {
                display: inline-block;
                padding: 12px 30px;
                background: $main-color;
                border: 1px solid $main-color;
                color: $white-color;
                font-size: 16px;
                font-weight: 400;
                transition: 0.6s;
                &:hover {
                    text-decoration: none;
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        span {
            a {
                text-decoration: none;
                color: #666666;
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    height: 100vh;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font-size: 50px;
        font-family: $main-font-family;
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
}

/*================================================
Privacy Section CSS
=================================================*/
.conditions-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.privacy-policy {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-privacy {
    h3 {
        font-size: 25px;
        margin-bottom: 10px;

        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    position: relative;
    z-index: 1;
    height: 100vh;
    background: {
        image: url(assets/img/page-title/bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &::before {
        z-index: -1;
        background: #000000;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 0.8;
    }
    .social-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        bottom: 30px;

        li {
            display: inline-block;
            margin: {
                left: 3px;
                right: 3px;
            }
            &.list-heading {
                display: block;
                color: $white-color;
                margin-bottom: 10px;
                font: {
                    size: 16px;
                    weight: 500;
                }
            }
            a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: $main-color;
                border: 1px solid $main-color;
                color: $white-color;
                border-radius: 50%;
                margin: 0 2px;
                display: inline-block;

                &:hover {
                    background-color: transparent;
                    color: $main-color;
                    transition: 0.6s;
                    -webkit-transition: 0.5s;
                }
            }
        }
    }
}
.coming-soon-content {
    text-align: center;
    max-width: 820px;
    margin: -80px auto 0;

    h1 {
        color: $white-color;
        margin-bottom: 15px;
        font: {
            size: 46px;
            weight: 700;
        }
    }
    p {
        color: $white-color;
        font-size: 14px;
        margin: 0 auto;
        max-width: 620px;
        line-height: 30px;
    }
    form {
        position: relative;
        margin: 35px auto 55px;
        max-width: 520px;

        .email-input {
            display: block;
            width: 100%;
            height: 56px;
            border: none;
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
                0px 0px 0px 5px rgba(255, 255, 255, 0.3);
            border-radius: 40px;
            padding: 15px 25px;
            outline: 0 !important;
            background: #f1f2f3;
        }
        .submit-btn {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 50px;
            background: $main-color;
            color: $white-color;
            border: none;
            border-radius: 40px;
            width: 130px;
            outline: 0 !important;
            cursor: pointer;
            text-transform: uppercase;
            font: {
                size: 14px;
                weight: 600;
            }
            &:hover,
            &:focus {
                background-color: $main-color;
            }
        }
    }
    #timer {
        div {
            background: $white-color;
            display: inline-block;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            color: $main-color;
            box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
            margin: 0 10px;
            padding-top: 18px;
            font: {
                size: 40px;
                weight: 700;
            }
            span {
                display: block;
                margin-top: -4px;
                color: #777777;
                font: {
                    size: 14px;
                    weight: 600;
                }
            }
        }
    }
}

/*================================================
Contact Section CSS
=================================================*/
.single-contact-box {
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
    padding: 30px;
    margin-bottom: 30px;

    i {
        line-height: 50px;
        width: 50px;
        height: 50px;
        border: 1px dashed $main-color;
        border-radius: 50%;
        font-size: 20px;
        display: inline-block;
        color: $main-color;
        transition: all 0.5s;
        margin-bottom: 20px;
    }
    &:hover {
        i {
            background-color: $main-color;
            color: $white-color;
        }
    }
    .content-title {
        h3 {
            font-size: 25px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            font-size: 16px;
            margin-bottom: 5px;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Services Details CSS
=================================================*/
.services-details-overview {
    margin: {
        bottom: 60px;
        top: 60px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        h3 {
            margin-bottom: 15px;
            font-size: 24px;
        }
        .features-text {
            margin-top: 25px;

            h4 {
                margin-bottom: 10px;
                font-size: 18px;
            }
        }
        .services-details-accordion {
            margin-top: 25px;
        }
    }
}
.services-details-video {
    position: relative;

    .details-video {
        text-align: center;
        position: absolute;
        left: 0px;
        right: 0;
        top: 40%;
        transform: translateY(-25%);
        margin: auto;

        .video-btn {
            display: inline-block;
            width: 80px;
            height: 80px;
            line-height: 80px;
            background-color: $white-color;
            border-radius: 50%;
            color: $main-color;
            position: relative;
            z-index: 1;
            font-size: 35px;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid $white-color;
                animation: ripple 2s linear 1s infinite;
            }
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid $white-color;
                animation: ripple 2s linear infinite;
            }
            &:hover,
            .video-content .video-btn:focus {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    .text {
        margin-top: 30px;

        h3 {
            font-size: 24px;
            margin: {
                top: 25px;
                bottom: 10px;
            }
        }
    }
}

/*================================================
Projects Details Area CSS
=================================================*/
.project-details-image {
    margin-bottom: 30px;
}
.projects-details-desc {
    margin-top: 5px;

    h3 {
        margin-bottom: 13px;
        font-size: 24px;
    }
    .features-text {
        margin-top: 20px;
        margin-bottom: 25px;

        h4 {
            margin-bottom: 12px;
            font-size: 18px;
            font-weight: 600;

            i {
                font-size: 16px;
                margin-right: 4px;
                color: $main-color;
            }
        }
    }
    .project-details-info {
        display: flex;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            top: 35px;
        }
        .single-info-box {
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font-size: 18px;
            }
            span {
                display: block;
                color: $optional-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin-right: 8px;
                }
                a {
                    color: $optional-color;
                    display: inline-block;

                    &:hover {
                        color: $main-color;
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font-size: 21px;

            &::before {
                content: "";
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_fria_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog-details/1.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog-details/2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog-details/3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: capitalize;
                    margin: {
                        top: -2px;
                        bottom: 3px;
                    }
                    font: {
                        size: 14px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 16px;
                        family: $main-font-family;
                        weight: 700;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    size: $font-size;
                    weight: 600;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 600;
                size: 14px !important;
            }
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover,
            &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 21px;

                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            font: {
                size: 24px;
            }
            margin: {
                bottom: 15px;
                top: 25px;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 40px;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        }
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;

            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;

            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                }
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        margin-left: 5px;
                        font-size: 12px;

                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}
blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 700;
            size: 22px !important;
        }
    }
    cite {
        display: none;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: flex;
        flex-wrap: wrap;

        .nav-previous {
            flex: 0 0 50%;
            max-width: 50%;

            a {
                display: inline-block;
                font-weight: 600;

                i {
                    margin-right: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;

            a {
                display: inline-block;
                font-weight: 600;

                i {
                    margin-left: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    weight: 400;
                }
            }
        }
    }
}
.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;

    .comments-title {
        line-height: initial;
        font-size: 23px;
        position: relative;
        margin: {
            bottom: 30px;
        }
    }
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: capitalize;
                position: relative;
                z-index: 1;
                font: {
                    size: 12px;
                    weight: 600;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 0.1em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                size: 16px;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: 0.8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            color: $paragraph-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font-size: 23px;

            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            }
        }
        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }
        label {
            display: block;
            font-weight: 400;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="week"],
        input[type="month"],
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="number"],
        textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;

            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 4px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: capitalize;
                transition: $transition;
                font: {
                    weight: 700;
                    size: 14px;
                }
                &:hover,
                &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}

/*================================================
Fooetr Area CSS
=================================================*/
.footer-section {
    background-color: #f5f3f3;
}
.subscribe-area {
    margin-bottom: 50px;

    .subscribe-content {
        h2 {
            font-size: 35px;
            margin-bottom: 0;
        }
    }
    .newsletter-form {
        position: relative;

        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 60px;
            padding-left: 25px;
            border-radius: 10px;
            outline: 0;
            color: $black-color;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 50px;
            padding: 0 30px 1px;
            border-radius: 10px;
            transition: $transition;
            cursor: pointer;
            font: {
                size: 15px;
                weight: 700;
            }
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
}
.single-footer-widget {
    margin-bottom: 30px;

    .footer-heading {
        margin-bottom: 20px;

        h3 {
            font-size: 22px;
        }
    }
    .footer-social {
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-right: 10px;
            list-style-type: none;

            a {
                display: block;
                width: 35px;
                height: 35px;
                background-color: #e5e3e3;
                border-radius: 4px;
                line-height: 35px;
                text-align: center;
                color: $black-color;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
                .flaticon-facebook,
                .flaticon-twitter,
                .flaticon-pinterest {
                    position: relative;
                    top: 0.5px;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .footer-quick-links {
        padding-left: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 12px;
            font-size: 15px;
            font-weight: 600;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-info-contact {
        position: relative;
        padding-left: 40px;
        line-height: 1.8;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
        i {
            position: absolute;
            left: 0;
            font-size: 25px;
            color: $main-color;
        }
        h3 {
            margin-bottom: 3px;
            font-size: 18px;
        }
        span {
            font-size: $font-size;
        }
    }
}
.copyright-area {
    background-color: $black-color;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    .copyright-area-content {
        p {
            color: $white-color;

            i {
                position: relative;
                top: 1px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                color: $white-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            text-align: right;

            li {
                display: inline-block;
                position: relative;
                color: $white-color;
                margin: {
                    left: 10px;
                    right: 10px;
                }
                a {
                    color: $white-color;
                    display: inline-block;

                    &:hover {
                        color: $main-color;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    right: -11px;
                    width: 1px;
                    height: 14px;
                    background-color: $white-color;
                }
                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: $white-color;
    background-color: $main-color;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    transition: 0.9s;
    border-radius: 50%;
    border: 1px solid $main-color;

    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        font-size: 30px;

        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $black-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        border-radius: 50%;
    }
    &:hover,
    &:focus {
        color: $white-color;

        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}

.eye-psw {
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
}
